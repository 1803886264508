@font-face {
  font-family: 'BauerBodoniBT';
  font-style: normal;
  src: local('BauerBodoniBT'), url("./fonts/Bauer-Bodoni-BT.ttf") format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";



body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
